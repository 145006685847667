

import { ModelSelect, ModelListSelect  } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'

import UMUM from         '../../library/umum'
import DATA_MASTER from  '../../library/dataMaster'
import FETCHING from  '../../library/fetching'


import Joi from "joi";

const schema = Joi.object().keys({
    username: Joi.string().regex(/^[a-zA-Z0-9_]*$/).min(6).max(13).required(),
    password: Joi.string().min(6).required(),
  });



export default {
  components: {
      ModelSelect, ModelListSelect, 
  },

  data() {
    return {
      list_instansi:[],
      list_unitkerja:[],
      

      form : {
          id : '',
          uraian : '',
          createdAt : '',
      },

      dataku: {
          id : '',
          nama: '',
          username : "",
          hp: "",
          email: "",
          password : "",
          confirmPassword: "",
          menu_klp : 0,
      },

      filterku : {
        instansi : '',
        unit_kerja : '',
        fileStat : false,
      },
     
    
      list_data : [],
      list_klp : [],

      errorMessage: '',
      signingUp : false,
    
      UMUM : UMUM,
      DATA_MASTER : DATA_MASTER,
      FETCHING : FETCHING,

      nm_jabatan : '',
      nm_unit_kerja : '',

      instansi_id : '',
      unit_kerja : '',
      unit_kerja_full : '',
      unit_kerja_edit : '',

      page_first: 1,
      page_last: 0,
      page_limit : 10,
      cari_value: "",

      cek_load_data : true,


      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      mdl_password : false,
     
    }
  },
  methods: {


    getView : function(){
      this.cek_load_data = true;
      fetch(this.$store.state.url.URL_DM_REGISTER + "view", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value,
              page_limit : this.page_limit,
              // unit_kerja : this.filterku.unit_kerja,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.cek_load_data = false;
              console.log(res_data);
      });
    },

    addData : function() {

      this.errorMessage = "";
      if (this.validUser()) {
          // Jika user sdh valid lakukan pengiriman data ke server
          const body = {
            username: this.dataku.username,
            password: this.dataku.password
          };
          this.signingUp = true;

          fetch(this.$store.state.url.URL_DM_REGISTER+'signup', {
              method: "POST",
              body: JSON.stringify(this.dataku),
              headers: {
                  "content-type": "application/json",
                  authorization: "kikensbatara " + localStorage.token
              }
          }).then((response) => {
              this.signingUp = false;
              // console.log(response)
              if (response.ok) {
                  this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
                  this.getView();
              }
              else{
                return response.json().then(error => {
                  throw new Error(error.message);
                });

              }
          })
          .catch((error) => {
            setTimeout(() => {
              this.signingUp = false;
              this.errorMessage = error.message;
            }, 1000);
          });
          

      }else{
        console.log("tidak valid");

      }
      },

    editData: function() {
        fetch(this.$store.state.url.URL_DM_REGISTER + "editData", {
            method: "POST",
            headers: {
                'content-type' : 'application/json',
                authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                id : this.dataku.id,
                menu_klp: this.dataku.menu_klp, 
            })
        }).then(res_data => {
            this.Notify('Sukses Mengubah Data', 'primary', 'check_circle_outline');
            this.getView();
        });
    },

    editDataPassword: function() {
      if (this.dataku.password !== this.dataku.confirmPassword) {
          this.errorMessage = "Password dan Confirm Password harus sama. !";
          return false;
      }
      else{
        this.errorMessage = '';
        fetch(this.$store.state.url.URL_DM_REGISTER + "editDataPass", {
            method: "POST",
            headers: {
                'content-type' : 'application/json',
                authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                id : this.dataku.id,
                password : this.dataku.password,
            })
        }).then(res_data => {
            this.$store.commit('notifAdd', 'Mengubah')
            this.getView();
        });

      }

    },

    removeData : function(idnya){
      fetch(this.$store.state.url.URL_DM_REGISTER + "removeData", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.dataku),
        }).then(res_data => {
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
            this.getView();
        });
    },



    validUser : function(){


      if (this.dataku.password !== this.dataku.confirmPassword) {
          this.errorMessage = "Password dan Confirm Password harus sama. !";
          return false;
      }
      // Memulai proses validasi melalui skema Joi yang sebelumnya dibuat
      // req.body merupakan data yang di kirim oleh client dan schema merupakan skema joi yg di buat sebelumnya
      const body = {
              username: this.dataku.username,
              password: this.dataku.password
      };


      const result = schema.validate(body);
      // console.log(result)

      if (result.error === null || result.error === undefined) {
          console.log("SDH BENAR")
          return true;
      }
      if (result.error.message.includes("username")) {
          // jika pesan error yang dihasilkan mengandung char "username" maka
          this.errorMessage = "Username tidak valid (Min : 6 dan Max : 14 Karakter)";
      } else {
          this.errorMessage = "Password tidak valid (Min : 6 Karakter)";

      }
      // console.log(this.errorMessage);
      return false;
    },

    selectData : function(data){
      console.log(data)
        this.dataku.id = data.id;
        this.dataku.username = data.username;
        this.dataku.nama = data.nama;
        this.dataku.hp = data.hp;
        this.dataku.email = data.email;
        this.dataku.menu_klp = data.menu_klp;
        // this.dataku.unit_kerja = data.unit_kerja;
    
    },

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx;
        },



        cari_data : function(){
            this.page_first = 1;
            this.getView();
            console.log('asasasa');
            // this.dipanggilawait()
        },

        selectBiodata (item) {
          return `${item.nama} | NIP. ${item.nip}`
        },


        getAsyncPost : async function() {
          this.list_klp = await FETCHING.postMasterKlpMenu();
        },

        dipanggilawait : async function(){
          this.list_instansi  = await DATA_MASTER.getUnitKerja(this.filterku.instansi);
          // this.list_unitkerja = await DATA_MASTER.getUnitKerjaAutocomplete(this.filterku.instansi)
        },
        dipanggilawait2 : async function(){
          // this.list_instansi  = await DATA_MASTER.getUnitKerja(this.filterku.instansi);
          this.list_unitkerja = await DATA_MASTER.getUnitKerjaAutocomplete(this.filterku.instansi, this.filterku.unit_kerja)
        },



    // ====================================== PAGINATE ====================================
  },



  mounted () {

    // var get_profile = JSON.parse(localStorage.profile);
    // this.filterku.unit_kerja = get_profile.profile.unit_kerja;
    // this.filterku.instansi = get_profile.profile.instansi_id;
    // this.unit_kerja_full = get_profile.profile.unit_kerja_nama;
    // this.unit_kerja = get_profile.profile.unit_kerja;
    // this.dataku.unit_kerja = get_profile.profile.unit_kerja;

    // DATA_MASTER.getInstansi();
    // DATA_MASTER.getUnitKerja(this.filterku.instansi);
    // DATA_MASTER.getBiodata(this.dataku.unit_kerja);
    
    this.getAsyncPost()

    this.getView();

  },
  watch: {
        dataku: {
            handler() {
                this.errorMessage = "";
            },
            deep: true
        }
  },
}
